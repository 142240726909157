




































































import { Component } from "vue-property-decorator";
import Header from "@/components/designer/header/Header.vue";
import { User, UserProfileSchema } from "@/models/user/user";
import UserService from "@/services/user_service";
import AuthService from "@/services/auth_service";
import Share from "@/components/share/Share.vue";
import {
  AuthError,
  GenericError,
  NotFoundError,
  PermissionsError,
} from "@/services/error_service";
import { mixins } from "vue-class-component";
import { ConfigCreator } from "@/mixins/configurator";

@Component({
  components: { Header, Share },
})
export default class UserProfile extends mixins(ConfigCreator) {
  protected userService = new UserService();
  protected authService = new AuthService();
  protected user: null | User = null;
  protected pwRequested = false;
  protected firstName = "";
  protected lastName = "";
  protected nameRules = [
    (v: string) => !!v || "Name is required",
    (v: string) => /^[\p{L} ,.'-]+$/u.test(v) || "Only letters allowed in name",
  ];
  protected email = "";
  protected emailRules = [
    (v: string) => !!v || "E-mail is required",
    (v: string) => /.+@.+/.test(v) || "E-mail must be valid",
  ];
  protected company = "";
  protected msrpMultiplier = "1";
  protected msrpMultiplierRules = [
    (v: string) => !!v || "MSRP Multiplier is required",
    (v: string) => /^[0-9]*\.?[0-9]+$/.test(v) || "Only numbers (including decimals) are allowed in MSRP Multiplier",
    (v: string) => parseFloat(v) >= 1 || "MSRP Multiplier must be greater than or equal to 1",
  ];

  protected companyRules = [
    (v: string) => !!v || "Company is required",
    (v: string) => /^[\p{L} ,.'-]+$/u.test(v) || "Only letters allowed in company",
  ];

  async created() {
    await this.getUserInfo();
  }

  public async getUserInfo(): Promise<void> {
  const loader = this.$loading.show();
  try {
    this.user = await this.userService.getUserProfile();
    this.firstName = this.user.first_name;
    this.lastName = this.user.last_name;
    this.email = this.user.email;
    this.company = this.user.company || "";
    this.msrpMultiplier = this.user.msrp_multiplier || "1";

    // Commit msrpMultiplier to Vuex
    this.$store.commit("user/setMsrpMultiplier", this.msrpMultiplier);
 
  } catch (err) {
    // Handle errors
  } finally {
    loader.hide();
  }
}

  public async requestNewPassword(): Promise<void> {
    window.location.replace(
      `${process.env.VUE_APP_LOGIN_URL}/request-email?request=password`
    );
  }

  protected submitProfile(): void {
    let loader = this.$loading.show();
    const body = {
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      company: this.company,
      msrp_multiplier: this.msrpMultiplier
    } as UserProfileSchema;
    try {
      this.userService.updateUserProfile(body);
      loader.hide();

      this.$store.dispatch("user/fetchUserProfile");

      loader = this.$loading.show(
        {},
        { before: this.$createElement("h1", "Saved!") }
      );
      setTimeout(() => loader.hide(), 500);
    } catch (err) {
      loader.hide();
      if (err instanceof PermissionsError) {
        PermissionsError.popup(err.message, err.statusCode);
      } else if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        GenericError.popup(err.message);
      }
    }
  }
}
